<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="800px">
    <template #activator="{ on: dialog }">
      <v-tooltip top>
        <template #activator="{ on: tooltip }">
          <v-btn
            block
            color="secondary"
            @click="onShowDialog"
            :disabled="!connector"
            v-on="{ ...tooltip, ...dialog }"
            data-test="activateButton"
          >
            Add Connector Block
          </v-btn>
        </template>
        <span>Add Connector Block</span>
      </v-tooltip>
    </template>
    <v-card color="grey lighten-2" tile>
      <v-card-title>
        <v-toolbar color="primary" dark>
          <v-toolbar-title
            >Add Connector Block -
            {{ connector ? connector.description : "" }}</v-toolbar-title
          >
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="onClose" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12" md="3">
              <v-img
                v-if="connector"
                :src="connector.svg_symbol"
                max-height="300"
                contain
              />
            </v-col>
            <v-col cols="12" md="9">
              <v-row dense>
                <v-col cols="12" md="8">
                  <v-text-field
                    dense
                    label="Reference"
                    name="reference"
                    type="text"
                    autocomplete="off"
                    autofocus
                    hide-details
                    v-model="connectorBlockName"
                    data-test="reference"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    block
                    :disabled="!connectorBlockName"
                    color="secondary"
                    @click="onSave"
                    data-test="saveButton"
                    >Save Connector</v-btn
                  >
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <span class="body-1 font-weight-bold primary--text" data-test="partNumberSpan">Part Number: {{connector ? connector.part_number : ''}}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConnectorPinModal",
  props: {
    connector: {
      type: Object,
      default: null,
    },
    tpcbConfigId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      connectorBlockName: null,
    };
  },
  methods: {
    onShowDialog() {
      this.connectorBlockName = null;
    },
    onClose() {
      this.dialog = false;
    },
    onSave() {
      const actionPayload = {
        tpcb_config: this.tpcbConfigId,
        ref: this.connectorBlockName,
        connector: this.connector.pk,
        pin_blocks: [],
      };
      this.$emit("saveData", actionPayload);
      this.onClose();
    },
  },
};
</script>
